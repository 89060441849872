var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = void 0;
var Action;
(function (Action) {
  Action["INITIALIZE"] = "APP::CLIENT::INITIALIZE";
})(Action = exports.Action || (exports.Action = {}));
export default exports;
export const __esModule = exports.__esModule;
const _Action = exports.Action;
export { _Action as Action };